<template>
    <div class="policy center">
        <bread-crumb></bread-crumb>
        <div class="tab-box">
          <div class="tab-item" v-for="(item,index) in tabLists"  :class="{active:currentIndex === index}" :key="index">
             <span @click="changeTab(item.type,index)">
               {{item.name}}
             </span>
          </div>
        </div>
        <div class="notice-content" v-if="policyData.length>0" >
            <policy-list :listData="policyData" :showText="false"></policy-list>
        </div>
       <el-empty v-else description="暂无数据"></el-empty>

      <div class="pagination">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="start"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
    import policyList from '@/components/policyList'
    import breadCrumb from '@/components/common/breadCrumb'
    import {getGraphInfoByClass} from "../../api/homeService";
    export default {
        components: {
            policyList,
            breadCrumb,
        },
        data() {
            return {
                start:1,
                size:10,
                total:0,
                policyData: [],
                isActive:true,
                tab:'5',
                currentIndex:0,
                currentType:['2','8'],
                tabLists:[
                 {name:'全部',type:['2','8']},
                 {name:'通告',type:['2']},
                 {name:'公告',type:['8']},
               ]
            }
        },
        mounted(){
            this.getGraphInfoList();
        },
        methods:{
            clickTab(tab, event) {
                this.getGraphInfoList();
            },
            getGraphInfoList(){
                const data = {lxList:this.currentType,start:this.start,size:this.size};
                getGraphInfoByClass(data).then(res=>{
                    this.policyData=res.data;
                    this.total = res.total;
                })
            },
            handleSizeChange(val){
                this.size = val;
                this.getGraphInfoList();
            },
            handleCurrentChange(val){
                this.start = val;
                this.getGraphInfoList();
            },
            handleClick(tab, event) {
                console.log(tab, event);
            },
           changeTab(type,index){
              this.currentIndex = index;
              this.currentType = type;
              this.getGraphInfoList();
           }

        }
    }
</script>
<style lang="scss">
    .policy {
        .tab-box{
          display: flex;
          margin: 8px 0px 0px 0px;
          width: 100%;
          border-bottom:1px solid #ECF1F3;
          .tab-item{
            width: 80px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            color: black;
            cursor: pointer;
            margin: 0px 10px 0px 0px;
            span{
              width: 100%;
              height: 100%;
              display: inline-block;
            }
          }
          .active{
            background: #039be5;
            color: white;
          }
        }
        .active {
            color: rgba(0, 120, 255, 1);
        }
        .zcfg-tabs {
            border: none;
            box-shadow: none;
            .block{
                padding:15px 0px;
            }
            .el-tabs__header {
                margin: 20px 0 0px;
                border-bottom:1px solid #ECF1F3;
                background-color: transparent;

                .el-tabs__item {
                    border: none;
                    font-size: 16px;
                    padding: 0px 0px !important;
                    color: #000000;
                    line-height: 40px;
                    width: 192px;
                    height: 40px;
                    border-radius: 6px 6px 0px 0px;
                    text-align: center;
                }

                .el-tabs__item.is-active {
                    background-color: #ECF1F3;
                }

                .el-tabs__item:not(.is-active):hover {
                    color: #808080;
                }

                .el-tabs__active-bar {
                    display: none;
                }
            }

            .el-tabs__content {
                padding: 0 !important;
            }

            .el-tabs__nav-wrap::after {
                display: none;
            }
        }
    }
    .pagination{
        text-align:center;
        margin:20px 0;
        .el-pagination{
            font-weight:500;
        }
    }
</style>
